<!-- @format -->

<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left">
			<div class="go-back" @click="$router.push({ name: 'procedures' })"><icon-arrow-back v-if="$resize && $mq.above(780)" /></div>
		</div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{ procedure.id ? $t("procedures.update_procedure") : $t("procedures.add_new_procedure") }}</h1>
				<div class="action">
					<v-popover
						class="help"
						offset="2"
						trigger="hover"
						placement="bottom"
						popoverBaseClass="popover-tbf btn-animated"
						:delay="{ show: 0, hide: 0 }"
					>
						<a class="btn-tbf blue only-icon " :href="helpData.link" target="_blank" v-if="helpData">
							<div class="icon"><icon-question /></div>
						</a>

						<template slot="popover">
							<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
						</template>
					</v-popover>

					<button class="btn-tbf white only-icon" @click="$router.push({ name: 'procedures' })">
						<icon-close class="icon-close" />
					</button>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t("procedures.new_procedure") }}</div>
					<div class="description">{{ $t("procedures.description_procedure") }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.name") }}*</label>
							<div v-if="$v.procedure.name.$error" class="error-msg">{{ $t("validator.required") }}</div>
							<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{ has_error: $v.procedure.name.$error }">
							<div class="icon-left"><icon-procedures /></div>
							<div class="icon-right" v-if="procedure.name != ''" @click.stop="procedure.name = ''">
								<icon-close class="icon-clear" />
							</div>
							<input
								type="text"
								:placeholder="$t('procedures.name_placeholder')"
								class="input-text"
								v-model="procedure.name"
							/>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.role") }}*</label>
							<div v-if="$v.selectedRoles.$error" class="error-msg" style="margin-right: 10px;">
								{{ $t("validator.required") }}
							</div>
							<div class="create-new" v-if="$auth.user().rights.admins_instance_crud" @click="showModal('role')">
								{{ $t("procedures.add_role") }}
							</div>
						</div>
						<div class="input-box" v-bind:class="{ has_error: $v.selectedRoles.$error }">
							<div class="icon-left"><icon-role /></div>
							<multiselect
								class="hide-tags multiselect_checkbox"
								v-bind:class="{ populate: selectedRoles.length }"
								v-model="selectedRoles"
								:options="optionsRoles"
								:allow-empty="true"
								:show-labels="false"
								:multiple="true"
								:close-on-select="false"
								:clear-on-select="false"
								track-by="id"
								label="name"
								:disabled="!roleInputVisibility"
								@input="getResponsibilities"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t("procedures.choose_role") }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{
										values[0].name
									}}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen"
										>{{ values.length }} {{ $t("filters.selected") }}</span
									>
								</template>
								<template slot="option" slot-scope="props">
									<div class="checkbox">
										<div class="checkmark"></div>
										<span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t("general.no_result") }}</template>
								<template slot="noOptions">{{ $t("general.empty_list") }}</template>
							</multiselect>
						</div>
					</div>

					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.categories") }}</label>
							<div class="create-new" v-if="$auth.user().rights.admins_instance_crud" @click="showModal('category')">
								{{ $t("procedures.add_category") }}
							</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-category /></div>
							<div class="icon-right" v-if="selectedCategories.length" @click.stop="selectedCategories = []">
								<icon-close class="icon-clear" />
							</div>
							<multiselect
								class="hide-tags multiselect_checkbox"
								v-bind:class="{ populate: selectedCategories }"
								v-model="selectedCategories"
								:options="optionsCategories"
								:allow-empty="true"
								:show-labels="false"
								:multiple="true"
								:close-on-select="false"
								track-by="id"
								label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t("procedures.choose_category") }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{
										values[0].name
									}}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen"
										>{{ values.length }} {{ $t("filters.selected") }}</span
									>
								</template>
								<template slot="option" slot-scope="props">
									<div class="checkbox" :style="'padding-left: calc(15px * ' + props.option.level_depth + ');'">
										<div class="checkmark"></div>
										<span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t("general.no_result") }}</template>
								<template slot="noOptions">{{ $t("general.empty_list") }}</template>
							</multiselect>
						</div>
					</div>

					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.manager") }}*</label>
							<div v-if="$v.selectedUser.$error" class="error-msg">{{ $t("validator.required") }}</div>
						</div>
						<div class="input-box" v-bind:class="{ has_error: $v.selectedUser.$error }">
							<div class="icon-left"><icon-people /></div>
							<multiselect
								v-model="selectedUser"
								:options="users"
								:allow-empty="false"
								:show-labels="false"
								:multiple="false"
								:close-on-select="true"
								track-by="id"
								label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t("procedures.choose_manager") }}
									</span>
								</template>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								<template slot="option" slot-scope="props">{{ props.option.name }}</template>
								<template slot="noResult">{{ $t("general.no_result") }}</template>
								<template slot="noOptions">{{ $t("general.empty_list") }}</template>
							</multiselect>
						</div>
					</div>

					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.role_responsibilities") }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-people /></div>
							<multiselect
								class="hide-tags multiselect_checkbox"
								v-model="selectedRoleResponsibilities"
								v-bind:class="{ populate: selectedRoleResponsibilities }"
								:options="optionsRoleResponsibilities"
								:allow-empty="true"
								:show-labels="false"
								:multiple="true"
								:close-on-select="false"
								:clear-on-select="false"
								:preserve-search="true"
								track-by="id"
								label="name"
								group-values="responsibilities"
								group-label="name"
								:group-select="true"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t("procedures.choose_role_responsibilities") }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{
										values[0].name
									}}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen"
										>{{ values.length }} {{ $t("filters.selected") }}</span
									>
								</template>
								<template slot="option" slot-scope="props">
									<span class="text" v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
									<div class="checkbox" v-else>
										<div class="checkmark"></div>
										<span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t("general.no_results") }}</template>
								<template slot="noOptions">{{ $t("general.empty_list") }}</template>
							</multiselect>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t("procedures.steps") }}*</div>
					<div class="description">{{ $t("procedures.steps_description") }}</div>
				</div>
				<div class="form">
					<div class="saved-steps" v-if="savedSteps.length">
						<div class="box">
							<draggable v-model="savedSteps">
								<div class="group-steps" v-for="(step, index) in savedSteps">
									<div class="icon-step"><icon-step /></div>
									<div class="main-label">
										<div class="name" v-html="step.body"></div>
										<div class="actions">
											<button class="btn-tbf white" @click="editStep(step, index)"><icon-edit /></button>
											<button class="btn-tbf white" @click="deleteStep(index)"><icon-trash /></button>
										</div>
									</div>
									<div class="level-skill">
										<!-- {{ step ? step : 'undefined' }} -->
									</div>
								</div>
							</draggable>
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t("procedures.step") }}*</label>
							<label class="create-new" v-if="trainingShow == false" @click="trainingShow = true">{{
								$t("procedures.add_training")
							}}</label>
							<label class="create-new" v-else @click="trainingShow = false">{{ $t("procedures.hide_training") }}</label>
							<div v-if="$v.procedure.body.$error" class="error-msg">{{ $t("validator.required") }}</div>
							<div v-if="imageUploadError" class="error-msg">{{ imageUploadError }}</div>
						</div>
						<div class="input-box" id="quillEditor" v-bind:class="{ has_error: $v.procedure.body.$error }">
							<quill-editor ref="myQuillEditor" v-model="procedure.body" :options="editorOption" />
						</div>
					</div>
					<div class="input-group w-100" v-if="trainingShow == true">
						<div class="label-header">
							<label class="label">{{ $t("procedures.training") }}</label>
						</div>
						<div class="input-box" id="quillEditor">
							<quill-editor ref="myQuillEditor" v-model="procedure.training" :options="editorOption" />
						</div>
					</div>
					<div class="input-group input-group-btn" :class="[{ 'w-50': $resize && !$mq.above(601) }]">
						<div class="add-more-btn" style="padding-top: 3px;">
							<button v-if="!isEditStep" id="buttonCreateStep" class="btn-tbf blue" @click="addStep">
								<div class="loader"></div>
								<span class="text">{{ $t("procedures.add_step") }} </span>
							</button>
							<button v-else class="btn-tbf blue" id="buttonUpdateStep" @click="updateStep">
								<span class="text">{{ $t("procedures.update_step") }}</span>
							</button>
						</div>
					</div>
					<div v-if="isEditStep" class="input-group input-group-btn" :class="[{ 'w-50': $resize && !$mq.above(601) }]">
						<div class="add-more-btn" style="padding-top: 3px;">
							<button class="btn-tbf grey" @click="(isEditStep = false), (procedure.body = ''), (procedure.training = '')">
								<span class="text">{{ $t("general.cancel") }} </span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group last">
				<div class="data-group">
					<div class="title">{{ $t("procedures.triggers") }}</div>
					<div class="description">{{ $t("procedures.triggers_description") }}</div>
				</div>
				<div class="form">
					<div class="saved-skills" v-if="savedTriggers.length">
						<div class="box">
							<div class="group-skill" v-for="(trigger, index) in savedTriggers">
								<div class="main-label">
									<div class="name">
										{{
											trigger.type == "weekly" || trigger.type == "yearly"
												? $tc("trigger.text." + trigger.type, trigger.value.split(",").length)
												: $t("trigger.text." + trigger.type)
										}}
									</div>
									<div class="actions">
										<button class="btn-tbf white" @click="editTrigger(trigger, index)"><icon-edit /></button>
										<button class="btn-tbf white" @click="deleteTrigger(index)"><icon-trash /></button>
									</div>
								</div>
								<div class="level-skill">
									<template v-if="trigger.custom_value">
										<span>
											{{
												trigger.custom_value
													.map((a) => {
														return $t(`trigger.value.measurement_dynamically.${a}`);
													})
													.join(", ")
													.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
											}}
										</span>

										<span class="days">
											{{
												$t(`trigger.value.date_weekly`, {
													value: trigger.value
														.split(",")
														.map((el) => {
															return $t(`calendar.long_days.${el}`);
														})
														.join(", "),
												})
											}}
										</span>
									</template>
									<span v-else-if="['date'].includes(trigger.type)">
										{{ $t(`trigger.value.date_monthly`, { value: trigger.value }) }}
									</span>
									<span v-else-if="['procedure'].includes(trigger.type)">{{ trigger.entity_name }}</span>
									<span v-else-if="['weekly'].includes(trigger.type)">{{
										trigger.value
											.split(",")
											.map((el) => {
												return $t(`calendar.long_days.${el}`);
											})
											.join(", ")
									}}</span>
									<span v-else>{{ trigger.value }}</span>
								</div>
							</div>
						</div>
					</div>

					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.triggers") }}</label>
							<div v-if="$v.selectedTrigger.$error" class="error-msg">{{ $t("validator.required") }}</div>
						</div>
						<div class="input-box bg-white" v-bind:class="{ has_error: $v.selectedTrigger.$error }">
							<div class="icon-left"><icon-trigger /></div>
							<div class="icon-right" v-if="selectedTrigger" @click.stop="clearTriggerForm()">
								<icon-close class="icon-clear" />
							</div>
							<multiselect
								class="hide-tags"
								v-bind:class="{ populate: selectedTrigger }"
								v-model="selectedTrigger"
								:options="optionsTrigger"
								:allow-empty="false"
								:show-labels="false"
								:multiple="false"
								:close-on-select="true"
								track-by="id"
								label="name"
								@input="$v.$reset()"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t("procedures.choose_trigger") }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }"
									><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen"
										>{{ values.length }} {{ $t("create-tags.selected-users") }}</span
									></template
								>
								<template slot="noResult">{{ $t("general.no_result") }}</template>
								<template slot="noOptions">{{ $t("general.empty_list") }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group input-group-btn w-50" v-if="$resize && $mq.above(600)">
						<div class="add-more-btn">
							<button v-if="!isEdit" class="btn-tbf blue" @click="addTrigger">
								<span class="text">{{ $t("procedures.add_trigger") }}</span>
							</button>
							<button v-else class="btn-tbf blue" @click="updateTrigger">
								<span class="text">{{ $t("procedures.update_trigger") }}</span>
							</button>
						</div>
					</div>
					<div class="input-group w-100" v-if="selectedTrigger.value == 'procedure'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.procedures") }}</label>
							<div v-if="$v.selectedProcedure.$error" class="error-msg">{{ $t("validator.required") }}</div>
						</div>
						<div class="input-box" v-bind:class="{ has_error: $v.selectedProcedure.$error }">
							<div class="icon-left"><icon-procedures /></div>
							<div class="icon-right" v-if="!selectedProcedure" @click.stop="selectedProcedure = ''">
								<icon-close class="icon-clear" />
							</div>
							<multiselect
								class="hide-tags"
								v-bind:class="{ populate: !selectedProcedure }"
								v-model="selectedProcedure"
								:options="procedures"
								:allow-empty="true"
								:show-labels="false"
								:multiple="false"
								:close-on-select="true"
								track-by="id"
								label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t("procedures.choose_procedure") }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{
										values[0].name
									}}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen"
										>{{ values.length }} {{ $t("filters.selected") }}</span
									>
								</template>
								<template slot="noResult">{{ $t("general.no_result") }}</template>
								<template slot="noOptions">{{ $t("general.empty_list") }}</template>
							</multiselect>
						</div>
					</div>

					<div v-if="selectedTrigger.value == 'date'" class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.select_date") }}</label>
						</div>
						<!-- Varianta Noua -->
						<div class="input-box-with-dropdown">
							<div class="input-box box-select small-padd">
								<multiselect
									v-model="subTypeMonthly"
									:options="optionsMonthlyTypes"
									:allow-empty="false"
									:show-labels="false"
									:placeholder="$t('general.choose')"
								>
									<template slot="singleLabel" slot-scope="props">{{
										$t(`procedures.type_monthly.${props.option}`)
									}}</template>
									<template slot="option" slot-scope="props">{{
										$t(`procedures.type_monthly.${props.option}`)
									}}</template>
									<template slot="noResult">{{ $t("general.no_results") }}</template>
									<template slot="noOptions">{{ $t("general.empty_list") }}</template>
								</multiselect>
							</div>
							<div class="input-box value-type" v-if="subTypeMonthly == 'day_numbers'">
								<div class="icon-left"><icon-circle-check /></div>
								<div class="input-text-simple">{{ $t("procedures.type_check_day_numbers") }}</div>
							</div>
							<!-- v-bind:class="{ has_error: $v.monthlyTypeSequenceDay.$error }"  -->
							<!-- Trebuie facuta validararea -->
							<div class="input-box value-type" v-else-if="subTypeMonthly == 'sequence_days'">
								<div class="icon-left"><icon-repeat /></div>
								<multiselect
									class="hide-tags multiselect_checkbox"
									v-model="monthlyTypeSequenceDay"
									v-bind:class="{ populate: monthlyTypeSequenceDay }"
									:options="optionsMonthlyTypeSequenceDays"
									:allow-empty="true"
									:show-labels="false"
									:close-on-select="false"
									:multiple="true"
									track-by="value"
									label="name"
								>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t("procedures.choose_monthly_sequence_days") }}
										</span>
									</template>
									<template slot="selection" slot-scope="{ values, search, isOpen }">
										<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{
											$t(`procedures.monthly_sequence_days_option.${values[0].name}`)
										}}</span>
										<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen"
											>{{ values.length }} {{ $t("filters.selected") }}</span
										>
									</template>
									<template slot="option" slot-scope="props">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{
												$t(`procedures.monthly_sequence_days_option.${props.option.name}`)
											}}</span>
										</div>
									</template>
									<template slot="noResult">{{ $t("general.no_results") }}</template>
									<template slot="noOptions">{{ $t("general.empty_list") }}</template>
								</multiselect>
							</div>
						</div>
						<!-- Varianta clasica -->
						<!-- <div class="input-box br-white" v-if="subTypeMonthly == 'day_numbers'" v-bind:class="{ has_error: $v.repetitive_date.$error }">
							<div class="select-workdays">
								<div class="checkbox-workdays" v-for="day in optionsDates">
									<span @click="selectDayOfMonth(day)" class="checkmark" v-bind:class="{ active: repetitive_date == day }">{{ day }}</span>
								</div>
							</div>
						</div> -->
					</div>
					<div
						v-if="selectedTrigger.value == 'weekly' || (selectedTrigger.value == 'date' && subTypeMonthly == 'sequence_days')"
						class="input-group w-100"
						:class="$resize && $mq.above(600) ? 'w-50' : 'w-100'"
					>
						<div class="label-header">
							<label class="label">{{ $t("procedures.select_day") }}</label>
							<div v-if="$v.weekly.$error" class="error-msg">{{ $t("validator.required") }}</div>
						</div>
						<div class="input-box br-white" v-bind:class="{ has_error: $v.weekly.$error }">
							<div class="select-workdays weekly">
								<div class="checkbox-workdays" v-for="day in days">
									<span
										@click="selectDay(day)"
										class="checkmark"
										v-bind:class="{ active: weekly.includes($t('calendar.small_days.' + day.toLowerCase())) }"
										>{{ day }}</span
									>
								</div>
							</div>
						</div>
					</div>
					<div class="input-group w-100" v-if="selectedTrigger.value == 'date' && subTypeMonthly == 'day_numbers'">
						<div class="label-header">
							<label class="label">{{ $t("procedures.select_monthly_days") }}</label>
							<!-- <div v-if="$v.monthlyDays.$error" class="error-msg">{{ $t("validator.required") }}</div> -->
						</div>
						<!-- v-bind:class="{ has_error: $v.monthlyDays.$error }" -->
						<div class="input-box br-white">
							<div class="select-workdays">
								<div class="checkbox-workdays" v-for="day in optionsMonthlyDays">
									<span
										@click="selectDayOfMonth(day)"
										class="checkmark"
										v-bind:class="{ active: monthlyDays.includes(day) }"
										>{{ day }}</span
									>
								</div>
							</div>
						</div>
					</div>
					<div v-if="selectedTrigger.value == 'event'" class="input-group w-100">
						<div class="input-box bg-white" v-bind:class="{ has_error: $v.event_description.$error }">
							<textarea rows="3" :placeholder="$t('procedures.event_placeholder')" v-model="event_description"></textarea>
						</div>
					</div>
					<div v-if="selectedTrigger.value == 'yearly'" class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t("procedures.select_date") }}</label>
							<div v-if="$v.yearly.$error" class="error-msg">{{ $t("validator.required") }}</div>
						</div>
						<div class="input-box">
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="dateOfYear"
								class="calendar-tbf frequency"
								:is-multiple-date-picker="true"
								:is-multiple="true"
								:calendars-count="3"
								:is-separately="false"
								:change-month-step="3"
								:hidden-elements="['leftAndRightDays']"
								:limits="limitsRange"
								@dayClicked="selectDayOfYear"
							>
								<template v-slot:datePickerInput="props">
									<input
										class="vfc-single-input custom-input-picker"
										type="text"
										:value="props.selectedDate ? moment(props.selectedDate, 'YYYY-M-D').format('DD MMMM') : ''"
										v-if="Object.keys(dateOfYear).length"
									/>
								</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group input-group-btn w-100" v-if="$resize && $mq.below(599)">
						<div class="add-more-btn">
							<button v-if="!isEdit" class="btn-tbf blue" @click="addTrigger">
								<span class="text">{{ $t("procedures.add_trigger") }}</span>
							</button>
							<button v-else class="btn-tbf blue" @click="updateTrigger">
								<span class="text">{{ $t("procedures.update_trigger") }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<button class="btn-tbf grey" @click="$router.push({ name: 'procedures' })">
				<span class="text">{{ $t("general.cancel") }}</span>
			</button>
			<div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!procedure.id">
				<div class="loader"></div>
				<span class="text">{{ $t("procedures.new_procedure") }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t("procedures.update_procedure") }}</span>
			</button>
		</div>
	</div>
	<loader-procedures-create v-else />
</template>

<script>
import IconArrowBack from "../../Icons/ArrowLong";
import IconClose from "../../Icons/Close";
import IconPeople from "../../Icons/People";
import IconTrigger from "../../Icons/Trigger";
import IconProcedures from "../../Icons/Procedures";
import IconRole from "../../Icons/Role";
import IconDate from "../../Icons/Date";
import IconEdit from "../../Icons/Edit";
import IconTrash from "../../Icons/Trash";
import IconStep from "../../Icons/Step";
import IconCategory from "../../Icons/Category";
import IconCircleCheck from "../../Icons/CircleCheck";
import { required, requiredIf } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import LoaderProceduresCreate from "../../PagesLoaders/ProceduresCreate";
import IconQuestion from "../../Icons/QuestionHelp";
import IconRepeat from "../../Icons/Repeat";

import Quill from "quill";
import ImageUploader from "quill-image-uploader/src/quill.imageUploader.js";
const ImageResize = require("quill-image-resize-module").default;

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);

export default {
	components: {
		IconArrowBack,
		IconClose,
		IconPeople,
		IconProcedures,
		IconTrigger,
		IconRole,
		IconDate,
		IconEdit,
		IconTrash,
		IconCategory,
		IconStep,
		draggable,
		LoaderProceduresCreate,
		IconQuestion,
		IconCircleCheck,
		IconRepeat,
	},
	data() {
		return {
			loaded: false,
			roleInputVisibility: true,
			procedure: {
				name: "",
				body: "",
				training: "",
			},
			optionsRoles: [],
			selectedRoles: [],
			imageUploadError: "",
			editorOption: {
				modules: {
					toolbar: [
						[{ header: [1, 2, 3, false] }],
						["bold", "italic", "underline", "strike"],
						["blockquote", "code-block"],
						[{ list: "ordered" }, { list: "bullet" }, { align: [] }],
						[{ color: [] }, { background: [] }],
						["link", "image", "video"],
						["clean"],
					],
					imageResize: { displaySize: true },
					imageUploader: {
						upload: (file) => {
							this.imageUploadError = "";

							return new Promise((resolve, reject) => {
								let objData = new FormData();
								objData.append("file", file);

								axios
									.post("procedures/store-images", objData)
									.then(({ data }) => {
										resolve(data.data.file_url);
									})
									.catch((error) => {
										this.imageUploadError = error.response.data.errors.file ? error.response.data.errors.file[0] : "";
										reject();
									});
							});
						},
					},
				},
			},
			users: [],
			selectedUser: "",
			optionsTrigger: [
				{ name: this.$t("trigger.other_procedure"), value: "procedure", id: 1 },
				{ name: this.$t("trigger.an_event"), value: "event", id: 2 },
				{ name: this.$t("trigger.a_date"), value: "date", id: 3 },
				{ name: this.$t("trigger.weekly_date"), value: "weekly", id: 4 },
				{ name: this.$t("trigger.a_yearly_date"), value: "yearly", id: 5 },
			],
			selectedTrigger: "",
			procedures: [],
			selectedProcedure: "",
			event_description: "",
			optionsMonthlyDays: Array.from({ length: 31 }, (_, i) => i + 1),
			savedTriggers: [],
			indexTrigger: "",
			isEdit: false,
			savedSteps: [],
			indexStep: "",
			isEditStep: false,
			stepValidation: false,
			triggerValidation: false,
			saveValidations: true,
			error_message: "",
			selectedCategories: [],
			optionsCategories: [],
			draftMode: false,
			days: [
				this.$t("calendar.small_days.sunday"),
				this.$t("calendar.small_days.monday"),
				this.$t("calendar.small_days.tuesday"),
				this.$t("calendar.small_days.wednesday"),
				this.$t("calendar.small_days.thursday"),
				this.$t("calendar.small_days.friday"),
				this.$t("calendar.small_days.saturday"),
			],
			weekly: [],
			yearly: [],
			errorsBe: {},
			helpData: this.$store.getters["help_links/currentLink"](this.$route.name),
			dateOfYear: {},
			limitsRange: { min: "", max: "" },
			newStartDay: "",
			trainingShow: false,
			selectedRoleResponsibilities: [],
			optionsRoleResponsibilities: [],
			subTypeMonthly: "day_numbers",
			optionsMonthlyTypes: ["day_numbers", "sequence_days"],
			monthlyTypeSequenceDay: [],
			optionsMonthlyTypeSequenceDays: [
				{ name: "first", value: 0 },
				{ name: "second", value: 1 },
				{ name: "third", value: 2 },
				{ name: "fourth", value: 3 },
				{ name: "last", value: 4 },
			],
			monthlyDays: [],
		};
	},
	created() {
		this.limitsRange.min = moment()
			.startOf("year")
			.format("D/M/YYYY");
		this.limitsRange.max = moment()
			.endOf("year")
			.format("D/M/YYYY");
	},
	computed: {
		editor() {
			return this.$refs.myQuillEditor.quill;
		},
	},
	async mounted() {
		await this.getFilters();

		this.$root.$on("updateListProcedureCreate", (roleData, categoryData) => {
			if (roleData) {
				this.optionsRoles.push(roleData);
				this.selectedRoles.push(roleData);
			} else {
				this.optionsCategories.push(categoryData);
				this.selectedCategories.push(categoryData);
				// this.selectedCategory = this.optionsCategories.find(el => el.id == categoryData.id)
			}
		});

		if (this.$route.params.slug) {
			this.draftMode = true;
			await this.getProcedureData();
		} else {
			setTimeout(() => {
				this.loaded = true;
				setTimeout(() => {
					$(".opacity-page").addClass("show");
				}, 0);
			}, 0);
		}
	},
	validations: {
		procedure: {
			name: { required },
			body: {
				requiredIf: requiredIf(function() {
					return this.savedSteps.length == 0;
				}),
			},
		},
		selectedRoles: { required },
	},
	validations() {
		let validatorObj = {
			procedure: {
				name: { required: false },
				body: { required: false },
			},
			selectedRoles: { required: false },
			selectedUser: { required: false },
			selectedTrigger: { required: false },
			selectedProcedure: { required: false },
			event_description: { required: false },
			weekly: { required: false },
			yearly: { required: false },
			monthlyDays: { required: false },
			monthlyTypeSequenceDay: { required: false },
		};

		if (this.saveValidations) {
			validatorObj.procedure.name = { required };
			validatorObj.selectedUser = { required };
			validatorObj.selectedRoles = { required };
		}

		if (this.stepValidation) {
			validatorObj.procedure.body = { required };
		}

		if (this.triggerValidation) {
			validatorObj.selectedTrigger = { required };
			validatorObj.selectedProcedure = {
				required: requiredIf(function() {
					return this.selectedTrigger.value == "procedure";
				}),
			};
			validatorObj.event_description = {
				required: requiredIf(function() {
					return this.selectedTrigger.value == "event";
				}),
			};
			validatorObj.weekly = {
				required: requiredIf(function() {
					return this.selectedTrigger.value == "weekly";
				}),
			};
			validatorObj.yearly = {
				required: requiredIf(function() {
					return this.selectedTrigger.value == "yearly";
				}),
			};
			validatorObj.monthlyDays = {
				required: requiredIf(function() {
					return this.selectedTrigger.value == "date" && this.subTypeMonthly == "day_numbers";
				}),
			};
			validatorObj.monthlyTypeSequenceDay = {
				required: requiredIf(function() {
					return this.selectedTrigger.value == "date" && this.subTypeMonthly == "sequence_days";
				}),
			};
		}

		return validatorObj;
	},
	methods: {
		async getFilters() {
			let paramsFilters = { roles: true, procedures: true, users: true, categories: true };

			if (this.$auth.user().rights_name.includes("procedure_role_admin")) {
				paramsFilters.procedure_role_admin = true;
			}

			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, { params: paramsFilters })
				.then(({ data }) => {
					this.optionsRoles = data.data.roles;
					this.procedures = data.data.procedures;
					this.users = data.data.users;
					this.optionsCategories = data.data.categories;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					if (!this.$route.params.slug && this.$auth.user().rights_name.includes("procedure_role_admin")) {
						this.optionsRoles.map((el) => {
							el.$isDisabled = !el.enabled;
						});
						this.roleInputVisibility = true;
					}
				});
		},
		getValue(value) {
			var filtered = this.procedures.filter((item) => item.id == value);
			return filtered.map((el) => el.name).join(", ");
		},
		// formatDate(date) {
		// 	var localDate = this.$moment(date).local();
		// 	var splitStr = localDate.format("LL").toLowerCase().split(' ');
		// 	for (var i = 0; i < splitStr.length; i++) {
		// 		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		// 	}
		// 	return splitStr.join(' ');
		// },
		showModal(type) {
			this.$root.$emit("open_modal", type, false, false);
		},
		async getProcedureData() {
			await axios
				.get(`/procedures/${this.$route.params.slug}/edit`)
				.then(({ data }) => {
					let proceduresData = data.data;
					this.procedure.name = proceduresData.name;
					this.procedure.id = proceduresData.id;
					this.procedure.slug = proceduresData.slug;

					proceduresData.roles.map((role) => {
						this.selectedRoles.push(this.optionsRoles.find((el) => el.id == role.id));
					});

					this.getResponsibilities();

					this.$nextTick(() => {
						this.selectedRoleResponsibilities = data.data.role_responsibilities;
					});

					proceduresData.categories.map((category) => {
						this.selectedCategories.push(this.optionsCategories.find((el) => el.id == category.id));
					});

					this.savedTriggers = proceduresData.triggers;
					this.savedSteps = this.draftMode ? proceduresData.draft_steps : proceduresData.steps;

					if (proceduresData.responsible) {
						this.selectedUser = this.users.find((el) => el.id == proceduresData.responsible.id);
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 404) {
							this.$router.push({ name: "not-found" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					if (this.$auth.user().rights.admins_instance_crud) {
						this.roleInputVisibility = true;
						setTimeout(() => {
							this.loaded = true;
							setTimeout(() => {
								$(".opacity-page").addClass("show");
							}, 0);
						}, 0);
					} else {
						this.checkVisibilityRoles();
					}
				});
		},
		getResponsibilities() {
			// Deletes the responsibilities that belong to unchecked roles
			this.selectedRoleResponsibilities = this.selectedRoleResponsibilities.filter((responsibility) =>
				this.selectedRoles.some((role) => role.id === responsibility.role_id)
			);

			axios
				.get(`${process.env.VUE_APP_ORGANIGRAM_BE_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, {
					params: { roles: true, roles_ids: this.selectedRoles.map((el) => el.id) },
				})
				.then(({ data }) => {
					this.optionsRoleResponsibilities = data.data.roles;
				});
		},
		addStep() {
			this.saveValidations = false;
			this.triggerValidation = false;
			this.stepValidation = true;

			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.savedSteps.push({ body: this.procedure.body, training: this.procedure.training });
				this.procedure.body = "";
				this.procedure.training = "";
				this.stepValidation = false;
				this.$v.$reset();
			}
		},
		editStep(step, index) {
			this.procedure.body = step.body;
			this.procedure.training = step.training;
			this.indexStep = index;
			this.isEditStep = true;
			this.procedure.training ? (this.trainingShow = true) : "";
		},
		updateStep() {
			this.saveValidations = false;
			this.triggerValidation = false;
			this.stepValidation = true;

			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.savedSteps[this.indexStep].body = this.procedure.body;
				this.savedSteps[this.indexStep].training = this.procedure.training;
				this.isEditStep = false;
				this.procedure.body = "";
				this.procedure.training = "";
				this.stepValidation = false;
				this.$v.$reset();
			}
		},
		deleteStep(index) {
			this.savedSteps.splice(index, 1);
		},
		addTrigger() {
			this.saveValidations = false;
			this.triggerValidation = true;
			this.stepValidation = false;
			this.$v.$touch();

			if (!this.$v.$invalid) {
				let triggerObj = {};
				triggerObj.type = this.selectedTrigger.value;
				switch (this.selectedTrigger.value) {
					case "procedure":
						triggerObj.value = this.selectedProcedure.id;
						triggerObj.entity_name = this.selectedProcedure.name;
						break;
					case "event":
						triggerObj.value = this.event_description;
						break;
					case "date":
						if (this.subTypeMonthly == "day_numbers") {
							triggerObj.value = this.monthlyDays.join(", ");
						} else if (this.subTypeMonthly == "sequence_days") {
							triggerObj.value = this.weekly.join(",");
							triggerObj.custom_value = this.monthlyTypeSequenceDay.map((el) => {
								return el.value;
							});
							triggerObj.frequency = "monthly_dynamically";
						}
						break;
					case "weekly":
						triggerObj.value = this.weekly.join(",");
						break;
					case "yearly":
						triggerObj.value = this.yearly.join(", ");
						break;
				}

				this.savedTriggers.push(triggerObj);
				this.triggerValidation = false;
				this.clearTriggerForm();
				this.$v.$reset();
				this.dateOfYear = {};
				this.monthlyDays = [];
			}
		},
		editTrigger(trigger, index) {
			this.isEdit = true;
			this.triggerSelected = trigger;
			this.indexTrigger = index;

			switch (trigger.type) {
				case "procedure":
					this.selectedTrigger = { name: this.$t("trigger.other_procedure"), value: "procedure", id: 1 };
					this.selectedProcedure = this.procedures.find((el) => el.id == trigger.value);
					break;
				case "event":
					this.selectedTrigger = { name: this.$t("trigger.an_event"), value: "event", id: 2 };
					this.event_description = trigger.value;
					break;
				case "date":
					if (trigger.custom_value) {
						this.selectedTrigger = { name: this.$t("trigger.a_date"), value: "date", id: 3 };
						this.monthlyTypeSequenceDay = trigger.custom_value.map((id) => {
							return this.optionsMonthlyTypeSequenceDays.find((el) => el.value == id);
						});
						this.weekly = trigger.value.split(",");
						this.subTypeMonthly = "sequence_days";
					} else {
						this.subTypeMonthly = "day_numbers";
						this.selectedTrigger = { name: this.$t("trigger.a_date"), value: "date", id: 3 };
						this.monthlyDays = trigger.value.split(",").map(Number);
					}
					break;
				case "weekly":
					this.selectedTrigger = { name: this.$t("trigger.weekly_date"), value: "weekly", id: 4 };
					this.weekly = trigger.value.split(",");
					break;
				case "yearly":
					this.selectedTrigger = { name: this.$t("trigger.yearly_date"), value: "yearly", id: 7 };
					this.yearly = trigger.value.split(", ");
					this.dateOfYear = {
						selectedDate: false,
						selectedDateTime: false,
						multipleDateRange: [],
						selectedDatesItem: "",
						selectedHour: "00",
						selectedMinute: "00",
						dateRange: { end: "", start: "" },
						selectedDates: this.yearly.map(function(a) {
							return { date: moment(a, "DD MMMM").format("D/M/YYYY"), dateTime: false, hour: "00", minute: "00" };
						}),
					};
					break;
			}
		},
		deleteTrigger(index) {
			this.savedTriggers.splice(index, 1);
		},
		updateTrigger() {
			this.saveValidations = false;
			this.triggerValidation = true;
			this.stepValidation = false;
			this.$v.$touch();

			if (!this.$v.$invalid) {
				let triggerObj = {};
				triggerObj.type = this.selectedTrigger.value;

				switch (this.selectedTrigger.value) {
					case "procedure":
						triggerObj.value = this.selectedProcedure.id;
						triggerObj.entity_name = this.selectedProcedure.name;
						break;
					case "event":
						triggerObj.value = this.event_description;
						break;
					case "date":
						if (this.subTypeMonthly == "day_numbers") {
							triggerObj.value = this.monthlyDays.join(", ");
						} else if (this.subTypeMonthly == "sequence_days") {
							triggerObj.value = this.weekly.join(",");
							triggerObj.custom_value = this.monthlyTypeSequenceDay.map((el) => {
								return el.value;
							});
							triggerObj.frequency = "monthly_dynamically";
						}
						break;
					case "weekly":
						triggerObj.value = this.weekly.join(",");
						break;
					case "yearly":
						triggerObj.value = this.yearly.join(", ");
						break;
				}

				this.savedTriggers[this.indexTrigger] = triggerObj;
				this.triggerValidation = false;
				this.clearTriggerForm();
				this.$v.$reset();
			}
		},
		clearTriggerForm() {
			this.selectedTrigger = "";
			this.selectedProcedure = "";
			this.event_description = "";
			this.weekly = [];
			this.yearly = [];
			this.monthlyDays = [];
			this.dateOfYear = {};
			this.monthlyTypeSequenceDay = [];
			this.isEdit = false;
		},
		saveAction(type) {
			this.saveValidations = true;
			if (this.selectedTrigger) {
				this.triggerValidation = true;
			}
			if (this.savedSteps.length == 0) {
				this.stepValidation = true;
			}

			var buttonName = `button${type == "create" ? "Create" : "Update"}`;

			var btnSubmit = document.getElementById(buttonName);
			var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`);
			var btnSubmitText = document.querySelector(`#${buttonName} .text`);

			btnSubmit.disabled = true;
			btnSubmit.classList.add("loading");
			btnSubmitLoader.classList.add("onProgress");
			btnSubmitText.innerHTML = this.$t("btn-submit.loading");

			this.error_message = "";

			this.$v.$touch();

			if (!this.$v.$invalid) {
				if (this.procedure.body != "") {
					if (this.isEditStep) {
						this.savedSteps[this.indexStep].body = this.procedure.body;
						this.savedSteps[this.indexStep].training = this.procedure.training;
					} else {
						this.savedSteps.push({ body: this.procedure.body, training: this.procedure.training });
					}
				}

				if (this.selectedTrigger) {
					let triggerObj = {};
					switch (this.selectedTrigger.value) {
						case "procedure":
							triggerObj.type = this.selectedTrigger.value;
							triggerObj.value = this.selectedProcedure.id;
							triggerObj.entity_name = this.selectedProcedure.name;
							break;
						case "event":
							triggerObj.type = this.selectedTrigger.value;
							triggerObj.value = this.event_description;
							break;
						case "date":
							if (this.subTypeMonthly == "day_numbers") {
								triggerObj.value = this.monthlyDays.join(", ");
							} else if (this.subTypeMonthly == "sequence_days") {
								triggerObj.value = this.weekly.join(",");
								triggerObj.custom_value = this.monthlyTypeSequenceDay.map((el) => {
									triggerObj.frequency = "monthly_dynamically";
									return el.value;
								});
							}
							triggerObj.type = this.selectedTrigger.value;
							break;
						case "weekly":
							triggerObj.type = this.selectedTrigger.value;
							triggerObj.value = this.weekly.join(",");
							break;
						case "yearly":
							triggerObj.type = this.selectedTrigger.value;
							triggerObj.value = this.yearly.join(", ");
							break;
					}

					if (this.isEdit) {
						this.savedTriggers[this.indexTrigger] = triggerObj;
					} else {
						this.savedTriggers.push(triggerObj);
					}
				}

				var selectedRoles = this.selectedRoles.length
					? this.selectedRoles.map(function(a) {
							return a.id;
					  })
					: "";
				var selectedCategories = this.selectedCategories.length
					? this.selectedCategories.map(function(a) {
							return a.id;
					  })
					: "";
				// var savedTriggers = this.savedTriggers.length
				// 	? this.savedTriggers.map((el) => {
				// 			return { id: el.id, type: el.type, value: el.value };
				// 	  })
				// 	: "";

				let objData = {};
				objData.name = this.procedure.name;
				objData.responsible_id = this.selectedUser.id;
				objData.triggers = this.savedTriggers;
				objData.steps = this.savedSteps;
				objData.role_responsibilities = this.selectedRoleResponsibilities.map((el) => el.id);

				if (this.draftMode) {
					objData.for_draft = true;
				}

				if (selectedRoles.length) {
					objData.roles = selectedRoles;
				}
				if (selectedCategories.length) {
					objData.categories = selectedCategories;
				}

				if (type == "create") {
					this.createProcedure(objData, btnSubmit, btnSubmitText, btnSubmitLoader);
				} else if (type == "update" || this.autoSaveStep) {
					this.updateProcedure(objData, btnSubmit, btnSubmitText, btnSubmitLoader);
				}
			} else {
				btnSubmitLoader.classList.add("finish");
				setTimeout(() => {
					btnSubmitText.innerHTML = this.$t("btn-submit.error");
					btnSubmit.classList.add("error");
					btnSubmitLoader.classList.remove("onProgress", "finish");
					btnSubmit.classList.remove("loading");
					setTimeout(() => {
						btnSubmit.classList.remove("error");
						btnSubmitText.innerHTML =
							type == "create" ? this.$t("procedures.new_procedure") : this.$t("procedures.update_procedure");
						btnSubmit.disabled = false;
					}, 1000);
				}, 300);
			}
		},
		createProcedure(objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
			axios
				.post("/procedures/store", objData)
				.then(({ data }) => {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.success");
						btnSubmit.classList.add("completed");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							this.$auth.fetch();
							setTimeout(() => {
								this.$router.push({ name: "procedure-show", params: { slug: data.data.slug } });
							}, 500);
						}, 1000);
					}, 300);
				})
				.catch((error) => {
					btnSubmitLoader.classList.add("finish");
					this.errorsBe = error.response.data.errors;
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.$t("general.add");
							btnSubmit.disabled = false;
							if (error.response.status == 500) {
								this.error_message = this.$t("error.500");
							}
						}, 1000);
					}, 300);
				})
				.finally(() => {
					setTimeout(() => {
						btnSubmit.disabled = false;
					}, 1500);
				});
		},
		updateProcedure(objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
			axios
				.patch(`/procedures/${this.procedure.slug}`, objData)
				.then(({ data }) => {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.success");
						btnSubmit.classList.add("completed");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							this.$auth.fetch();
							setTimeout(() => {
								this.$router.push({ name: "procedure-show", params: { slug: data.slug }, query: { draft_version: true } });
							}, 500);
						}, 1000);
					}, 300);
				})
				.catch((error) => {
					btnSubmitLoader.classList.add("finish");
					this.errorsBe = error.response.data.errors;
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.$t("general.update");
							btnSubmit.disabled = false;
							if (error.response.status == 500) {
								this.error_message = this.$t("error.500");
							}
						}, 1000);
					}, 300);
				})
				.finally(() => {
					setTimeout(() => {
						btnSubmit.disabled = false;
					}, 1500);
				});
		},
		selectDayOfMonth(day) {
			if (this.monthlyDays.includes(day)) {
				this.monthlyDays.splice(
					this.monthlyDays.findIndex((el) => el == day),
					1
				);
			} else {
				this.monthlyDays.push(day);
			}
		},
		selectDay(day) {
			switch (day) {
				case this.$t("calendar.small_days.monday"):
					if (this.weekly.includes("monday")) {
						this.weekly.splice(this.weekly.indexOf("monday"), 1);
					} else {
						this.weekly.push("monday");
					}
					break;
				case this.$t("calendar.small_days.tuesday"):
					if (this.weekly.includes("tuesday")) {
						const index = this.weekly.indexOf("tuesday");
						this.weekly.splice(index, 1);
					} else {
						this.weekly.push("tuesday");
					}
					break;
				case this.$t("calendar.small_days.wednesday"):
					if (this.weekly.includes("wednesday")) {
						const index = this.weekly.indexOf("wednesday");
						this.weekly.splice(index, 1);
					} else {
						this.weekly.push("wednesday");
					}
					break;
				case this.$t("calendar.small_days.thursday"):
					if (this.weekly.includes("thursday")) {
						const index = this.weekly.indexOf("thursday");
						this.weekly.splice(index, 1);
					} else {
						this.weekly.push("thursday");
					}
					break;
				case this.$t("calendar.small_days.friday"):
					if (this.weekly.includes("friday")) {
						const index = this.weekly.indexOf("friday");
						this.weekly.splice(index, 1);
					} else {
						this.weekly.push("friday");
					}
					break;
				case this.$t("calendar.small_days.saturday"):
					if (this.weekly.includes("saturday")) {
						const index = this.weekly.indexOf("saturday");
						this.weekly.splice(index, 1);
					} else {
						this.weekly.push("saturday");
					}
					break;
				case this.$t("calendar.small_days.sunday"):
					if (this.weekly.includes("sunday")) {
						const index = this.weekly.indexOf("sunday");
						this.weekly.splice(index, 1);
					} else {
						this.weekly.push("sunday");
					}
					break;
				default:
					break;
			}
		},
		checkVisibilityRoles() {
			if (
				this.$auth.user().id == (this.selectedUser ? this.selectedUser.id : "") &&
				this.$auth.user().rights_name.includes("procedure_role_admin")
			) {
				if (this.selectedRoles.filter((el) => this.optionsRoles.filter((el) => el.enabled).includes(el)).length) {
					this.optionsRoles.map((el) => {
						el.$isDisabled = !el.enabled;
					});
					this.roleInputVisibility = true;
				} else {
					this.roleInputVisibility = false;
				}
			} else if (this.$auth.user().id == (this.selectedUser ? this.selectedUser.id : "")) {
				this.roleInputVisibility = false;
			} else if (this.$auth.user().rights_name.includes("procedure_role_admin")) {
				this.optionsRoles.map((el) => {
					el.$isDisabled = !el.enabled;
				});
				this.roleInputVisibility = true;
			}

			setTimeout(() => {
				this.loaded = true;
				setTimeout(() => {
					$(".opacity-page").addClass("show");
				}, 0);
			}, 0);
		},
		selectDayOfYear(day) {
			var date = day.hasOwnProperty("date") ? moment(day.date, "D/M/YYYY").format("DD MMMM") : day;

			if (this.yearly.includes(date)) {
				this.yearly.splice(
					this.yearly.findIndex((el) => el == date),
					1
				);
			} else {
				this.yearly.push(date);
			}
		},
	},
};
</script>

<style lang="scss">
.input-box-with-dropdown {
	display: flex;
	.input-box {
		&.box-select {
			margin-right: 10px;
			&.small-padd {
				.multiselect {
					width: 100px;
					.multiselect__input,
					.multiselect__single {
						padding: 0 30px 0 15px;
					}
				}
			}
		}
		&.value-type {
			flex: 1;
		}
		.input-text-link {
			padding: 0 15px 0 45px;
		}
		.input-text-simple {
			height: 38px;
			border-radius: 10px;
			color: #181b30;
			font-size: 12px;
			padding: 0 15px 0 45px;
			background: transparent;
			display: flex;
			align-items: center;
		}
	}
}
.quill-editor {
	min-height: 300px;
	display: flex;
	flex-direction: column;
	.ql-toolbar.ql-snow {
		border: 0;
		border-bottom: 1px solid #ccc;
	}
	.ql-toolbar.ql-snow + .ql-container.ql-snow {
		border: 0;
	}
	.ql-container {
		height: initial;
		flex: 1;
		// overflow-x: auto;
	}
	.ql-video {
		max-width: 100%;
		width: 500px;
		height: 281px;
		@media (min-width: 1025px) and (max-width: 1160px) {
			height: 160px;
		}
		@media (max-width: 570px) {
			height: 220px;
		}
		@media (max-width: 460px) {
			height: 170px;
		}
	}
	.ql-tooltip {
		&.ql-editing {
			&.ql-hidden {
				display: none;
			}
			display: flex;
			align-items: center;
			border-radius: 5px;
			background: #fdfdfd;
			border: 1px solid $borderCard;
			input {
				padding: 0 5px !important;
				height: 30px !important;
			}
			.ql-action {
				box-shadow: 0px 1px 4px 0px rgba(8, 129, 255, 0.3);
				background: $bgBlue;
				color: #fff;
				border: 1px solid $borderBlue;
				padding: 0 10px;
				font-size: 12px;
				border-radius: 4px;
				line-height: 20px;
				&:after {
					font-weight: 500;
					margin-left: 0;
				}
			}
		}
	}
}
</style>
